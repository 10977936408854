import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { GoogleAddressI, ResState } from '../../residential/residential-form/residential-form.component';
import { AddressAttributes, PolicyAddress } from '../models/address.model';
import { BrandingService } from './branding.service';
import { ConvertCoveragesService } from './convert-coverages.service';
import { PmAccount } from '../dto/pm-account';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { cloneDeep, omit } from 'lodash';
import { Insurable } from '../models/insurable.model';
import { Router } from '@angular/router';
import { PmaFirstForm, ResidentialForm } from '../interfaces/residential-data.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { YMDDate } from '../models';
import { isYMDDate } from '../utils';

interface Cache {
  hash: string;
  result: any;
}

const INSURABLES_BASE_ENDPOINT = '/insurables/';

const INSURABLES_GET_OR_CREATE_ENDPOINT = INSURABLES_BASE_ENDPOINT + 'get-or-create';

export interface PolicyApplication {
  id: number;
  installment_fee: number;
  installment_total: number;
  policy_fee: number;
  user: {
    id: number;
  };
  quote: {
    id: number;
    premium: {
      total_premium: number;
      total_tax: number;
      total_fee: number;
      total: number;
    }
  };
  invoices: {
    number: number;
    total_payable: number;
  }[];
  carrier_id?: number;
}

export interface GetOrCreateResponse {
  results_type: string;
  results: Insurable[];
  redirect_to?: string;
}

export interface LeaseCheckParams {
  email: string;
  first_name: string;
  insurable_id: number;
  last_name: string;
}


@Injectable({
  providedIn: 'root'
})
export class ResidentialService {
  readonly PMC_REDIRECT_TIMEOUT = 4500;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private brandingService: BrandingService,
    private convertCoveragesService: ConvertCoveragesService,
    private router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService) {}
  public static RENTERS_LOCAL_STORAGE_KEY = 'GCI_RENTERS_LOCAL_STORAGE_KEY2';
  cachedCoveregeLimits = [];

  getFormData(policyType: string, body?): Observable<any> {
      // test
    let policyBody = {};
    if (body) {
      this.addBrandingProfileIdToBody(body);
      policyBody = { policy_application: body };
    }
    return this.httpClient.post(`/policy-applications/new?policy_type=${policyType}`, policyBody);
  }

  getBillingStrategies(agencyId, carrierId?, policyType?) {
    let params: HttpParams = new HttpParams();
    if (agencyId) {
      params = params.append('agency_id', agencyId);
    }
    if (carrierId) {
      params = params.append('carrier_id', carrierId);
    }
    if (policyType) {
      params = params.append('policy_type',  policyType);
    }

    return this.httpClient.get<any[]>(`/billing-strategies?${params.toString()}`);
  }

  getCoverageOptions(body) {
    if (body && body.coverage_selections && body.coverage_selections.length) {
      body.coverage_selections = this.convertCoveragesService.convertCoverageSelections(body.coverage_selections);
    }
    return this.httpClient.post<any>(`/policy-applications/get-coverage-options`, body).pipe(
      map(res => {
        if (res.coverage_options) {
          res.coverage_options = this.convertCoveragesService.convertCoverageOptions(res.coverage_options);
        }
        return res;
      })
    );
  }

  getCoverageLimits(state: ResState) {
    const id = state.id;
    if (!id) {
      return throwError('');
    }
    let params: HttpParams = new HttpParams();

    if (state.number_insured) {
      params = params.append('number_insured', String(state.number_insured));
    }
    if (state.billing_period) {
      params = params.append('billing_period', state.billing_period);
    }
    if (state.deductible) {
      params = params.append('deductible', String(state.deductible));
    }
    if (state.hurricane) {
      params = params.append('hurricane', String(state.hurricane));
    }
    const hash = id + '/' + params.toString();
    const isHashed = this.cachedCoveregeLimits.find( (item: Cache) => item.hash === hash );

    if (isHashed !== undefined) {
      return of(isHashed.result);
    }
    return this.httpClient.get(`${INSURABLES_BASE_ENDPOINT}${id}/rates?${params.toString()}`)
      .pipe(map(respond => {
        this.cachedCoveregeLimits.push({ hash, result: respond});
        return respond;
      })
    );
  }

  searchBy(str: string, policyTypeId?) {
    let params: HttpParams = new HttpParams();
    params = params.append('search', str);

    if (policyTypeId) {
      params = params.append('policy_type_id', policyTypeId);
    }

    params = params.append('doNotThrowError', String(true));

    return this.httpClient.get<PolicyAddress[]>(`/addresses`, {params});
  }

  postCheckResidential(payload) {
    let url = '/policy-applications';
    this.addBrandingProfileIdToBody(payload);
    if (this.authService.getCurrentUser()) {
      url = '/user' + url;
    }
    return this.httpClient.post<PolicyApplication>(url, payload);
  }

  updatePolicyApplication(id, payload) {
    let url = '/policy-applications/' + id;
    this.addBrandingProfileIdToBody(payload);
    if (this.authService.getCurrentUser()) {
      url = '/user' + url;
    }
    return this.httpClient.put<PolicyApplication>(url, payload);
  }

  completeRentGuaranteeForm(id) {
    return this.httpClient.post<any>(`/policy-applications/${id}/rent_guarantee_complete`, {});
  }

  acceptPolicyQuote(id, body) {
    return this.httpClient.post(`/policy-quotes/${id}/accept`, body);
  }

  getCommercialClassification(state: string, category: string) {
    const codes = ['FL', 'TX'];
    let stateCode = 'CW';
    let majorCategory = '';

    if (category !== '') {
      majorCategory = `&major_category="${category}"`;
    }
    if (state && codes.some(x => x === state)) {
      stateCode = state;
    }
    return this.httpClient.get(`/class-codes?state_code=${stateCode}${majorCategory}`);
  }

  getExternalPaymentAuth(quoteId: string|number) {
    return this.httpClient.post(`/policy-quotes/${quoteId}/external-payment-auth`, {
      payment_method: 'card'
    });
  }

  getPayeezyToken(token) {
    return this.httpClient.get(`https://paymentjs.cardpaysolutions.com/tokens/${token}`);
  }

  getPolicyApplication(id) {
    let url = `/policy-applications/${id}`;
    if (this.authService.getCurrentUser()) {
      url = '/user' + url;
    }
    return this.httpClient.get(url);
  }

  getPublicPolicyApplicationById(id): Observable<any> {
    return this.httpClient.get( `/policy-applications/${id}`);
  }

  /*
   ** This method can be called from the PMA first step or Residential Form
   */
  getOrCreateInsurable(formData: PmaFirstForm | ResidentialForm): Observable<GetOrCreateResponse> {
    const accountId = this.brandingService.getBrandingInfoSnapshot()?.account_id || null;

    return this.httpClient.post<GetOrCreateResponse>(INSURABLES_GET_OR_CREATE_ENDPOINT, {
      address: formData?.address,
      unit: false,
      allow_creation: true,
      county: formData?.county,
      neighborhood: formData?.neighborhood,
      account_id: accountId
    }).pipe(
      tap((result: GetOrCreateResponse) => result.redirect_to ? this.handlePMCRedirect(formData, result) : '')
    );
  }

  /*
   ** This method can be called from the PMA first step or Residential Form
   */
  getOrCreateUnit(formData: PmaFirstForm | ResidentialForm): Observable<GetOrCreateResponse> {
    const accountId = this.brandingService.getBrandingInfoSnapshot()?.account_id || null;

    return this.httpClient.post<GetOrCreateResponse>(INSURABLES_GET_OR_CREATE_ENDPOINT, {
      allow_creation: true,
      address: formData.address,
      unit: formData.unit || true,
      titleless: !formData.unit,
      county: formData.county,
      neighborhood: formData.neighborhood,
      account_id: accountId
    }).pipe(
      tap((result: GetOrCreateResponse) => result.redirect_to ? this.handlePMCRedirect(formData, result) : '')
    );
  }

  /*
   ** This method is created to handle PMC redirect and is a callback for the get-or-create endpoint
   ** In case insurable does not belong to the current site, the user should be redirected to the organization who owns insurable
   */
  handlePMCRedirect(formData: PmaFirstForm | ResidentialForm, result: GetOrCreateResponse): void {
    const params = new URLSearchParams();
    params.append('first_name', formData.first_name);

    if (formData.middle_name) {
      params.append('middle_name', formData.middle_name);
    }

    params.append('last_name', formData.last_name);
    params.append('email', formData.email);
    params.append('address', formData.address);

    if (formData.unit) {
      params.append('unit', formData.unit);
    }

    if (formData.insurable_id) {
      params.append('insurable_id', formData.insurable_id);
    }

    this.toastrService.info(this.translateService.instant('pmc_redirect_notice'), null,{
      positionClass: 'toast-bottom-left',
    });

    setTimeout(() => {
      window.location.href = `https://${result.redirect_to}${location.pathname}?${params.toString()}`;
    }, this.PMC_REDIRECT_TIMEOUT)
  }

  getOrCreateUnitByBuildingId(address, unit, titleless, buildingId): Observable<GetOrCreateResponse> {
    const accountId = this.brandingService.getBrandingInfoSnapshot()?.account_id || null;

    return this.httpClient.post<GetOrCreateResponse>(INSURABLES_GET_OR_CREATE_ENDPOINT, {
      address,
      unit,
      titleless,
      allow_creation: true,
      insurable_id: buildingId,
      account_id: accountId
    });
  }

  getOrCreateBuilding(address, communityId): Observable<GetOrCreateResponse> {
    const accountId = this.brandingService.getBrandingInfoSnapshot()?.account_id || null;

    return this.httpClient.post<GetOrCreateResponse>(INSURABLES_GET_OR_CREATE_ENDPOINT, {
      address,
      unit: false,
      allow_creation: true,
      insurable_id: communityId,
      account_id: accountId
    });
  }

  getOrCreateInsurableByInsurableId(insurableId): Observable<any> {
    const accountId = this.brandingService.getBrandingInfoSnapshot()?.account_id || null;

    return this.httpClient.post(INSURABLES_GET_OR_CREATE_ENDPOINT, {
      allow_creation: false,
      communities_only: true,
      insurable_id: insurableId,
      account_id: accountId
    }).pipe(map(result => {
      /**
       * In the case there are buildings available under community
       * but the community itself has units to select also
       * we append the community to the list of buildings to make it's units be available to select
       */
      if (result['results'] && result['results'].length > 0
        && result['results'][0]['buildings'] && result['results'][0]['buildings'].length > 0
        && result['results'][0]['units'] && result['results'][0]['units'].length > 0) {
        result['results'] = result['results'].map(item => {
          const communityItem = cloneDeep(item);
          item.buildings = [...item?.buildings, omit(communityItem, 'buildings')];

          return item;
        });
      }
      return result;
    }));
  }

  getFullAddress(address: AddressAttributes) {
    return `${address.street_number || ''} ${address.street_name || ''}, ${address.city || ''}, ${address.state || ''} ${address.zip_code || ''}`;
  }

  addBrandingProfileIdToBody(body) {
    const brandingProfileId = this.brandingService.getBrandingInfoSnapshot().id;
    body.branding_profile_id = brandingProfileId;
    return body;
  }

  getInsurableById(id) {
    return this.httpClient.get(INSURABLES_BASE_ENDPOINT + id);
  }

  getCoverageRequirements(insurableId: number, startDate?: YMDDate) {
    if (!isYMDDate(startDate)) {
      startDate = '1900-01-01';
    }
    const body = {
      insurable_id: insurableId,
      start_date: startDate
    };
    return this.httpClient.post(`/coverage_requirements/configuration/community`, body);
  }

  getCounties(insurableId) {
    return this.httpClient.get(`${INSURABLES_BASE_ENDPOINT}${insurableId}/qbe-county`);
  }

  postCounty(insurableId, county) {
    return this.httpClient.post(`${INSURABLES_BASE_ENDPOINT}${insurableId}/qbe-county`, { county });
  }

  getCommunitiesByAccount(accountId: number): Observable<PmAccount[]> {
    return this.httpClient.get<PmAccount[]>(`/communities/${accountId}`);
  }

  /**
   * Returns all the accounts belongs to the agency
   */
  getAgencyAccounts(agencyId: number): Observable<PmAccount[]> {
    return this.httpClient.get<PmAccount[]>(`/agency-accounts/${agencyId}`);
  }

  /**
   * This method parses the Google Address object into a string
   */
  parseAddress(googleAddress: Address): string {
    const componentForm: GoogleAddressI = {
      street_number: 'short_name',
      route: 'short_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_3: 'short_name',
      postal_code: 'short_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name'
    };
    const addressObj = {} as GoogleAddressI;
    // @ts-ignore
    for (let i = 0; i < googleAddress.address_components.length; i++) {
      const addressType = googleAddress.address_components[i].types[0];
      if (componentForm[addressType]) {
        addressObj[addressType] = googleAddress.address_components[i][componentForm[addressType]];
      }
    }

    const locality = addressObj.locality ? addressObj.locality
                : addressObj.sublocality_level_1 ? addressObj.sublocality_level_1
                : addressObj.administrative_area_level_3 ? addressObj.administrative_area_level_3
                : addressObj.neighborhood;
    let address = `${addressObj.street_number} ${addressObj.route}, ${locality}, ${addressObj.administrative_area_level_1} ${addressObj.postal_code}`;

    /**
     * The following thing is a hack caused by the fact back-end does not return the preferred community
     */
    if (address === '21221 W Oxnard St, Los Angeles, CA 91367') {
      address = '21221 Oxnard St, Woodland Hills, CA, 91367';
    }

    return address;
  }

  /**
   * Format address
   */
  formatAddress(originalValue, address: Address): string {
    let newAddress = this.parseAddress(address);
    /**
     * Some addresses from Google Search come without street number so we prepend it from the input value
     */
    if (!newAddress.match(/^\d/g) && newAddress.match(/^\d/g)) {
      newAddress = originalValue.split(' ')[0] + ' ' + newAddress;
    }
    return newAddress;
  }

  /**
   * Checks if lease exists and retrieves the start date
   * @param params
   */
  public leaseSearch(params: LeaseCheckParams): Observable<{ start_date?: string }> {
    return this.httpClient.get<{ start_date?: string }>(
      `${INSURABLES_BASE_ENDPOINT}${params.insurable_id}/lease`,
      {
        params: omit(params, 'insurable_id'),
      }
    );
  }
}
